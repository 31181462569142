.navbar {
  position: relative;

  .logo-link {
    font-family: $font-family__name-title;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;

    strong {
      color: $primary-color;
    }

    span {
      color: $secondary-color;
    }
  }

  .header-link {
    font-family: $font-family-name-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 12px 8px;
    color: $color-2;

    &:hover {
      color: $secondary-button;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  .secondary-btn {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    width: 178px;
    height: 48px;
    border-radius: 8px;
    border: 2px solid $secondary-button;

    &:hover {
      background-color: $secondary-button;

      span {
        color: $color-white;
      }
    }

    span {
      color: $secondary-button;
      font-family: $font-family-name-base;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
}

.navbar-left {
  width: 618px;
  justify-content: space-between;
}

.navbar-right {
  width: 300px;
  justify-content: space-between;
}



// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .navbar-collapse {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 56px;
    bottom: 0;
    left: 0;
    background: rgba(11, 11, 11, 0.36);
    transition: none;
    z-index: 1;
  }

  .navbar-collapse-container {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background: $color-white;
    padding: 12px 20px 32px;

    .nav-item {
      padding: 12px 0;
      margin-bottom: 14px;
    }

    .header-link {
      color: $color-black;
      font-family: $font-family-name-base;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .separator {
      background: #CCDDDE;
      height: 1px;
      margin: 10px 0 24px;
      width: 100%;
    }

    .navbar-nav-bottom {
      a {
        display: flex;
      }

      .secondary-btn {
        width: 100%;
        margin-top: 24px;
      }
    }
  }

  .navbar {
    padding: 20px;
    box-shadow: none !important;
  }

  .container-fluid {
    padding: 0;
  }

  .navbar-toggler {
    border: none;
    padding: 0;

    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      background-image: url("../../images/navbar-close.png");
      width: 23px;
      height: 23px;
      transition: all .1s ease;
    }

    &[aria-expanded="false"] {
      .navbar-toggler-icon {
        background-image: url("../../images/navbar.png");
      }
    }
  }
}
