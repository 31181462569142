.action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 48px;
  border-radius: 100px;
  cursor: pointer;
  color: $dark-text;
  border: none;
  
  &.primary {
    background: $primary-button;
    transition: all .5s ease;
    
    img {
      transition: all .5s ease;
      margin-right: 10px;
    }
    
    &:hover {
      background: $secondary-button;
      color: $color-white;
      
      img {
        filter: invert(1);
      }
    }
  }
}