main,
header,
footer {
  //max-width: $layout-max-width;
  //margin: 0 auto;
}

main {
  overflow-x: hidden;
}

.row {
  --bs-gutter-x: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.row>* {
  padding-left: 0;
  padding-right: 0;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

p {
  font-family: $font-family-name-base;
  font-weight: normal;
}

.title {
  span {
    color: $secondary-color;
  }
}

.back-url {
  margin-bottom: 36px;
  
  &__link {
    text-decoration: none;
    font-family: $font-family__name-extra;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: $primary-color;
    background: $disable-button;
    border-radius: 6px;
    width: 154px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      transform: rotate(90deg);
      margin-right: 10px;
    }
    
    &:hover {
      opacity: .5;
    }
  }
}

.toolbar {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  
  &--tabs {
    display: flex;
    
    a {
      width: 90px;
      font-family: $font-family__name-extra;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: $secondary-color;
      text-decoration: none;
      padding-bottom: 9px;
      border-bottom: 1px solid $input-placeholder-color;
      position: relative;
      transition: all .3s ease;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 2px;
        width: 100%;
        background: transparent;
      }
      
      &.active,
      &:hover {
        color: $color-2;
        
        &::after {
          background: $color-2;
        }
      }
      
      &.active {
        pointer-events: none;
      }
    }
  }
}

// custom styles

.general-top-block {
  background-image: url("../../images/about-resource-gradient.png");
  background-repeat: no-repeat;
  background-position-y: 84%;
  background-position-x: -25%;
  background-size: 70%;
  padding-left: 0;
  padding-right: 0;
}


.btn-primary {
  width: 319px;
  padding: 20px 24px;
  border-radius: 100px;
  background: $secondary-color;
  color: $color-black;
  font-family: $font-family__name-extra;
  border: 2px solid $secondary-color;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in;

  &:hover {
    border: 2px solid $secondary-color;
    color: $secondary-color;
    background-color: $color-white;
  }
}

.main-block,
.about-resource-block,
.help-block-content,
.features-block,
.integration-block {
  padding-top: 80px;
  padding-bottom: 80px;
}

.main-block {
  background-image: url("../../images/main-block-gradient.png");
  background-repeat: no-repeat;
  background-position: 85%;
  align-items: center;
  position: relative;

  .main-block-col {
    max-width: 682px;
  }

  .column-right {
    padding-left: 36px;
  }

  .title {
    color: $primary-color;
    font-family: $font-family-name-base;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 24px;
  }

  .text {
    color: $p-text;
    font-family: $font-family-name-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 48px;
  }

  .btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.about-resource-block {
  font-family: $font-family-name-base;
  align-items: center;
  justify-content: space-between;

  .about-resource-col {
    max-width: 489px;
  }

  .title {
    color: $primary-color;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .text {
    color: $p-text;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%
  }
}

.help-block,
.features-block {
  .row {
    max-width: 1280px;
    margin: auto;
  }
}

.help-block {
  background-image: url("../../images/help-block-gradient.png");
  background-repeat: no-repeat;
  background-position-y: 10px;
  background-position-x: 125%;
  max-width: 1440px;
  margin: 0 auto;

  .help-block-content {
    justify-content: space-between;

    .column-left {
      max-width: 590px;
    }

    .column-right {
      max-width: 600px;
      align-items: center;
      display: flex;
    }
  }

  .help-block-title {
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .title {
    color: $primary-color;
    font-family: $font-family-name-base;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.5%;
  }

  .accordion {
    background: initial;
    font-family: $font-family-name-base;
    font-style: normal;
    line-height: 130%;
    max-width: 591px;

    .accordion-item,
    .accordion-button {
      border-radius: 0;
      border-right: 0;
      border-left: 0;
      border-color: $input-border-color;
    }

    .accordion-item {
      padding: 12px 24px;

      &:first-child {
        border-bottom: 0;
      }

      &:not(:first-of-type) {
        border-top: 1px solid $input-border-color;
        border-bottom: 0;
      }

      &:has(div.collapse.show) {
        box-shadow: 0 4px 21px 0 rgba(0, 0, 0, 0.05);
        margin-bottom: 16px;
        border-radius: 0 0 8px 8px;
        transition: all 0.2s ease-in;
      }

      &:last-child {
        border-bottom: 1px solid $input-border-color;
      }

      ul {
        list-style: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 20px;
      }
    }

    .accordion-button {
      background: initial;
      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none;
      padding: 12px 0;

      &::after {
        background-image: url('../../images/open.png');
        background-size: cover;
        width: 22px;
        height: 22px;
      }

      &:not(.collapsed)::after {
        background-image: url('../../images/close.png');
      }
    }

    .accordion-body {
      color: $p-text;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      padding: 0;

      p {
        padding-bottom: 20px;
      }
    }

    .accordion-icon {
      margin-right: 18px;
      height: 32px;
      width: auto;
    }
  }

  .help-block-desktop {
    width: 100%;
    cursor: pointer;
    border-radius: 45px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07), 0 20px 25px -5px rgba(0, 0, 0, 0.20);
  }
}

.features-block {
  background-color: $additional-background-color;
  font-family: $font-family-name-base;

  .features-title {
    padding-bottom: 80px;
  }

  .features-content {
    padding-bottom: 48px;
  }

  .title {
    color: $primary-color;
    text-align: center;
    font-family: $font-family__name-extra;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
  }

  .tab-container {
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1100px;
  }

  .nav-pills {
    width: 455px;
    
    .nav-link {
      text-align: left;
      background: rgba(255, 255, 255, 0.25);
      padding-top: 21px;
      padding-bottom: 16px;
      color: $primary-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      border-radius: 0;
      border-bottom: 1px solid $tab-border-color;

      &:first-child {
        border-top: 1px solid $tab-border-color;
      }

      &.active {
        background: $color-white;
        padding-top: 16px;
        padding-bottom: 21px;
        font-weight: 900;
        border-bottom: 0;
        border-top: 3px solid $secondary-color;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
        transition: padding .15s ease-in-out, background-color .15s ease-in-out;
      }

      .pill-icon {
        width: 20px;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  .tab-content {
    width: 461px;
    color: $p-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    .list {
      margin-top: 24px;
    }

    .tab-strong {
      display: block;
      margin-bottom: 12px;
    }

    .integration-content {
      margin-top: 24px;

      .integration-row {
        img {
          width: auto;
          max-height: 16px;
        }

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    ul {
      list-style: disc;
      padding-inline-start: 20px;
      margin-top: 12px;
      max-width: 333px;
    }
  }

  .accordion-secondary {
    .accordion-item {
      background: transparent;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $tab-border-color;

      &:first-child {
        border-top: 1px solid $tab-border-color;
      }
    }

    .accordion-header {
      margin: 0;
    }

    .accordion-button {
      padding: 10px 0;
      box-shadow: none;
      color: $primary-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      max-height: 41px;


      &:focus {
        box-shadow: none;
      }

      &::after {
        background-image: url('../../images/plus.png');
        background-size: cover;
        width: 24px;
        height: 24px;
      }

      &:not(.collapsed) {
        background: transparent;

        &::after {
          background-image: url('../../images/minus.png');
        }
      }
    }

    .accordion-body {
      color: $primary-color;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      padding: 12px 45px 12px 40px;
    }

    .accordion-button-icon {
      width: 30px;
      margin-right: 15px;
    }
  }

  .features-slider > div {
    max-width: 1100px;
    margin: auto;
  }

  .carousel {
    width: auto;
    margin: auto;
    position: relative;

    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
      width: auto;
    }

    .carousel-control-prev {
      justify-content: start;
      left: -7%;
    }

    .carousel-control-next {
      justify-content: end;
      right: -7%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 53px;
      height: 53px;
    }

    .carousel-control-prev-icon {
      background-image: url('../../images/prev.png');
    }

    .carousel-control-next-icon {
      background-image: url('../../images/next.png');
    }

    .carousel-inner img {
      width: 100%;
    }
  }
}

.form-block {
  padding-top: 128px;
  padding-bottom: 128px;
  background-image: url("../../images/form-block-gradient.png"), linear-gradient(90deg, $additional-background-color 50%, $color-3 100%); /* W3C */
  background-repeat: no-repeat;
  background-position: center center;

  .form-content {
    max-width: 1280px;
    margin: auto;
    text-align: center;
  }

  .title {
    color: $primary-color;
    font-family: $font-family__name-extra;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    padding-bottom: 30px;
  }

  .description {
    max-width: 810px;
    margin: auto;
    color: $p-text;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding-bottom: 30px;
  }

  .form {
    width: 524px;
    margin: auto;

    .row,
    .col-6 {
      padding: 0;
    }

    .form-control,
    .form-select {
      width: 256px;
      height: 56px;
      padding: 17px 16px;
      align-items: center;
      gap: 8px;
      margin: 0 0 12px;
      color: $color-4;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:focus {
        border-color: $secondary-color;
        box-shadow: 0 0 0 0.25rem rgba(62, 207, 142, .25);
      }
    }

    .form-select {
      color: $color-5;
    }

    .move-right {
      margin-left: 6px;
    }

    .col-12 {
      margin-top: 20px;
    }
  }
}

.integration-block {
  .row {
    align-items: center;
  }

  .title {
    color: $primary-color;
    font-family: $font-family-name-base;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.5%;
    margin: 0;
  }

  .integration-content {
    .integration-row:first-child {
        margin-bottom: 34px;
    }
  }
}

.integration-content {
  .integration-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.modal {
  .modal-dialog {
    max-width: 70%;
    height: auto;

    video {
      width: 100%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .integration-content {
    .integration-row {
      img {
        width: 80%;
        height: auto;
      }
    }
  }

  .features-block {
    .carousel {
      .carousel-control-prev {
        left: 0;
      }

      .carousel-control-next {
        right: 0;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 992px) {
  .mobile {
    display: none !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .main-block,
  .about-resource-block,
  .help-block-content,
  .features-block,
  .integration-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .desktop {
    display: none !important;
  }

  .main-block {
    background-position-x: 90%;
    background-position-y: 63%;
    background-size: contain;

    .main-block-col {
      max-width: initial;
    }
    
    .column-right {
      padding-top: 40px;
      padding-left: 0;
    }

    .title {
      font-size: 32px;
    }

    .main-image.mobile {
      width: 100%;
    }
  }

  .general-top-block {
    background-image: url("../../images/about-resource-gradient-mobile.png");
    background-position-y: 60%;
    background-position-x: center;
    background-size: 100%;
  }
  
  .about-resource-block {
    text-align: center;
    padding-bottom: 60px;

    .column-right {
      padding-top: 40px;
    }

    .about-resource-col {
      max-width: initial;
      text-align: left;
    }

    .title {
      font-size: 32px;
    }

    .main-image {
      width: 100%;
      max-width: 490px;
    }
  }

  .help-block {
    background-image: url("../../images/help-block-gradient-mobile.png");
    background-position-y: 90%;
    background-position-x: right;
    background-size: 75%;

    .title {
      font-size: 24px;
    }

    .accordion {
      max-width: initial;
    }


    .help-block-content {
      padding-top: 0;
      padding-bottom: 60px;
      justify-content: space-between;

      .column-left,
      .column-right {
        max-width: initial;
      }

      .column-right {
        align-items: center;
        display: inherit;
        padding-top: 70px;
      }
    }
  }

  .features-block {
    .title {
      font-size: 28px;
    }

    .features-title {
      padding-bottom: 32px;
    }

    .carousel {
      .carousel-item {
        margin-top: 20px;
      }

      .carousel-control-prev,
      .carousel-control-next {
        bottom: 0;
        top: 100%;
      }

      .carousel-control-prev {
        left: calc(100% - 95px);
      }

      .carousel-control-next {
        right: 0;

      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 40px;
        height: 40px;
      }
    }
  }

  .features-content {
    .accordion-main {
      font-family: $font-family-name-base;

      .accordion-header {
        border: none;
      }

      .accordion-item,
      .accordion-button {
        background: transparent;
        border-bottom: 1px solid $tab-border-color;
      }

      .accordion-button {
        color: $primary-color;
        text-align: left;
        background: rgba(255, 255, 255, 0.25);
        padding-top: 21px;
        padding-bottom: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        border-radius: 0;

        &:focus {
          box-shadow: none;
        }

        &:after {
          content: none;
        }

        &:not(.collapsed) {
          background: $color-white;
          border-bottom: 0;
          border-top: 3px solid $secondary-color;
          box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
          transition: padding .15s ease-in-out, background-color .15s ease-in-out;
        }
      }

      .accordion-button-icon {
        width: 20px;
        height: auto;
        margin-right: 10px;
      }

      .accordion-item {
        border: none;
      }

      .accordion-body {
        color: $p-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;

        .list {
          margin-top: 24px;
        }

        .tab-strong {
          display: block;
          margin-bottom: 12px;
        }

        .integration-content {
          margin-top: 24px;

          .integration-row {
            img {
              width: auto;
              max-height: 16px;
            }

            &:first-child {
              margin-bottom: 20px;
            }
          }
        }

        ul {
          list-style: disc;
          padding-inline-start: 20px;
          margin-top: 12px;
          max-width: 333px;
        }
      }

      .accordion-secondary {
        .accordion-item:first-child {
          border-top: 1px solid $tab-border-color;
        }

        .accordion-button {
          padding: 10px 0;
          box-shadow: none;
          font-weight: 400;
          max-height: initial;


          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed) {
            background: transparent;
            border-top: none;
          }
        }

        .accordion-body {
          font-size: 11px;
          line-height: 120%;
        }

        .accordion-button-icon {
          width: 25px;
        }
      }
    }
  }

  .form-block {
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url("../../images/form-block-gradient-mobile.png"), linear-gradient(90deg, $additional-background-color 50%, $color-3 100%); /* W3C */
    background-repeat: no-repeat;
    background-position: top center;

    .title {
      font-size: 32px;
    }

    .form {
      width: 100%;

      .col-6 {
        width: 100%;
      }

      .form-control,
      .form-select {
        width: 100%;
      }

      .move-right {
        margin-left: 0;
      }
    }
  }

  .integration-block {
    .title {
      text-align: center;
      font-size: 24px;
      padding-bottom: 24px;
    }

    .integration-content {
      display: flex;

      .integration-row {
        flex-basis: 50%;
        flex-direction: column;
        align-items: baseline;
        gap: 10px;

        div {
          height: 50px;
          display: flex;
          align-items: center;
        }

        .time-trackers-image img {
          width: 100%;
        }

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .modal .modal-dialog {
    max-width: 95%;
  }
  
}