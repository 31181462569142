$main-background-color: #FFFFFF;
$additional-background-color: #F3FAF8;
$primary-color: #3E3E3E;
$secondary-color: #3ECF8E;

$dark-text: #3E3E3E;
$p-text: #474747;

$color-white: #fff;
$color-black: #000;
$color-1: #FAFDEF;
$color-2: #707070;
$color-3: #E6F3F1;
$color-4: #868688;
$color-5: #2C2C2E;
$color-6: #0B212D;
$color-7: #EEEEEE;
$color-8: #FEFDF2;
$color-9: #F3FDFB;
$color-10: #E9EAEB;
$color-11: #FB2047;
$color-12: #FFE8EC;
$color-13: #FF4869;
$color-14: #FCFCE5;

$input-placeholder-color: #C1C7DE;
$input-border-color: #EBEBEB;
$tab-border-color: #CCDDDE;

$primary-button: #3ECF8E;
$secondary-button: #0F172A;
$disable-button: #F8F8F8;

