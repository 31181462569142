.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $color-6;
  color: $color-white;

  .row {
    margin: auto;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .logo {
    font-family: $font-family__name-title;
    font-size: 14.862px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .copyright {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .footer-links-block {
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;

    .footer-link {
      color: $color-white;
      font-family: $font-family-name-base;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
      
      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .footer {
    padding-top: 32px;

    .footer-logo-block,
    .copyright {
      margin-bottom: 20px;
    }
  }
}