@font-face {
  font-family: $font-family-name-base;
  src: url(../fonts/Campton-Book.eot);
  src: local($font-family-name-base),
  url(../fonts/Campton-Book.eot?#iefix) format('embedded-opentype'),
  url(../fonts/Campton-Book.woff) format('woff'),
  url(../fonts/Campton-Book.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-family__name-extra;
  src: url(../fonts/Roboto-Regular.eot);
  src: local($font-family__name-extra),
  url(../fonts/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
  url(../fonts/Roboto-Regular.woff) format('woff'),
  url(../fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: $font-family__name-title;
  src: url(../fonts/BakbakOne-Regular.eot);
  src: local($font-family__name-title),
  url(../fonts/BakbakOne-Regular.eot?#iefix) format('embedded-opentype'),
  url(../fonts/BakbakOne-Regular.woff2) format('woff2'),
  url(../fonts/BakbakOne-Regular.woff) format('woff'),
  url(../fonts/BakbakOne-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}