form {
  .form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 32px;
    }

    label {
      font-family: $font-family-name-base;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primary-color;
      margin-bottom: 10px;
    }

    input {
      padding: 8px 11px;
      width: 474px;
      max-height: 40px;
      height: 40px;
      border: 1px solid $input-border-color;
      border-radius: 6px;
      box-sizing: border-box;
      color: $secondary-color;

      &::placeholder,
      &::-webkit-datetime-edit-fields-wrapper {
        font-family: $font-family-name-base;
        font-weight: normal;
        font-size: 16px;
        color: $input-placeholder-color;
      }

      &:disabled {
        background: none;
      }
    }
  }

  .form-group {
    margin-bottom: 32px;

    legend {
      margin-bottom: 12px;
    }

    label {
      cursor: pointer;
    }

    fieldset {
      display: flex;
      padding: 0;
      margin: 0;
      border: none;

      > div {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $secondary-color;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;

    border-radius: 100%;
    background: $color-white;
  }

  [type="radio"]:not(:checked) + label:before {
    border: 1px solid $secondary-color;
  }

  [type="radio"]:checked + label:before {
    border: 1px solid $primary-color;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $primary-color;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all .2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
}

select,
input[type="datetime-local"] {
  cursor: pointer;
  padding: 8px 11px;
  width: 219px;
  height: 40px;
  background: $color-white;
  border: 1px solid $input-border-color;
  border-radius: 6px;
  font-family: $font-family-name-base;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary-color;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(../images/arrow.svg) no-repeat 95% 11px;
}

input[type="datetime-local"] {
  background: none;
  height: auto;
  padding: 6px 11px;
}

@media (max-width: 1440px) {
  form {
    .form-control {
      input {
        width: 311px;
      }
    }
  }
}

input[type="text"],
input[type="number"] {
  background: $color-white;
  border: 1px solid $input-border-color;
  border-radius: 6px;
  padding: 7px 11px;
  font-family: $font-family-name-base;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary-color;
}

textarea {
  border-radius: 6px;
  height: 246px;
  padding: 8px 11px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid $input-border-color;
  margin-bottom: 46px;

  &::placeholder {
    color: $color-7;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}